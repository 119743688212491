@keyframes cart-anm-t-b {
    0% { width: 0%; }
    100% { width: 4%; }
  }
  
  .border-animate-t {
    position: relative;
  }
  
  .border-animate-t::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 3px;
    width: 0%;
    background: linear-gradient(180deg, #fe5b3e 0, #fe7b49 100%);
    animation: cart-anm-t-b 5s linear infinite alternate;
  }
  